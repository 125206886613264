<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Users" /></div>
    <div class="buttonText ml-1">
      <button class="button ml-2 mt-6" @click.stop="create">
        New <v-icon size="20" color="white">mdi-plus</v-icon>
      </button>
      <h4 style="margin-top: 35px; margin-left: -20px" class="blue-grey--text">
        List of Branch Managers
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :options.sync="options"
              :server-items-length="totalDesserts"
              :loading="loading"
              class="elevation-1"
            ></v-data-table>
          </div>
        </template> </v-col
    ></v-row>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/PageTitle";
export default {
  name: "Dispensers",
  components: { PageTitle },
  data() {
    return {
      totalDesserts: 0,
      desserts: [],
      loading: true,
      options: {}
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Age", value: "age" },
        { text: "Type", value: "type" },
        { text: "School", value: "school" },
        { text: "Email", value: "email" }
      ];
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi().then(data => {
          this.desserts = data.items;
          this.totalDesserts = data.total;
        });
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi().then(data => {
      this.desserts = data.items;
      this.totalDesserts = data.total;
    });
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = this.getDesserts();
        const total = items.length;

        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          this.loading = false;
          resolve({
            items,
            total
          });
        }, 1000);
      });
    },
    getDesserts() {
      return [
        {
          name: "Kwaku Amponsah",
          age: 21,
          type: "Direct",
          school: "KNUST",
          email: "kwaku@gmailcom"
        },
        {
          name: "Sandra Addy",
          age: 23,
          type: "Direct",
          school: "KNUST",
          email: "sandra2@gmail.com"
        },
        {
          name: "Omar Bashir",
          age: 21,
          type: "In-direct",
          school: "Legon",
          email: "omar@gmailcom"
        },
        {
          name: "Gloria Amponsah",
          age: 21,
          type: "Direct",
          school: "UCC",
          email: "kwaku@gmailcom"
        },
        {
          name: "Sammy Ntow",
          age: 19,
          type: "Direct",
          school: "AIMS",
          email: "ntow123@gmailcom"
        }
      ];
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
